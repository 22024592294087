



















































































import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import CartItems from '@/components/desktop-pos/cart-items.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import Receipt from '@/components/Receipt.vue'
import { mapGetters, mapState } from 'vuex'
import Printer from '@/libs/imin-printer.esm.browser.min'
import html2canvas from 'html2canvas'
import OrderProvider from '@/resources/order.provider'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

const orderProvider = new OrderProvider()

@Component({
    components: {
        CustomerInfo,
        CartItems,
        PriceSummary,
        MemberCard,
        Receipt,
        DesktopPosNavbar
    },
    computed: {
        ...mapState({
            selectBranch: (state: any) => state.Store.selectBranch,
            selectPos: (state: any) => state.Store.selectPos
        }),
        ...mapGetters({
          branchAddress: 'Store/branchAddress'
        })
    }
})
export default class Reprint extends Vue {
    base64Url = ''

    bill: any = null

    status = ''

    selectBranch!: any

    branchAddress!: any

    selectPos!: any

    loading = false

    printLoading = false

    billId = ''

    async localPrint (): Promise<void> {
      const printer = new Printer()
      try {
          const connected = await printer.connect()
          if (connected) {
              this.status = 'กำลังเชื่อมต่อกับเครื่องปรินท์'
              const el: HTMLElement | any = this.$refs.receiptTemplate
              const elementToConvert = await html2canvas(el.$el, {
                scale: 1
              })
              if (elementToConvert) {
                this.base64Url = elementToConvert.toDataURL('image/png')
                this.status = 'กำลังเตรียมข้อมูลเพื่อปรินท์'
              } else {
                this.status = 'การเตรียมข้อมูลเพื่อปรินท์ผิดพลาด'
              }
              printer.initPrinter()
              if (this.base64Url) {
                  await printer.printSingleBitmap(this.base64Url, 53)
                  await printer.printAndFeedPaper(100)
                  await printer.partialCut()
                  this.status = 'กำลังทำการปรินท์'
                  setTimeout(() => {
                    this.status = 'ปรินท์เสร็จสิ้น'
                    this.billId = ''
                    this.bill = null
                  }, 5000)
              } else {
                this.status = 'การเตรียมข้อมูลเพื่อปรินท์ผิดพลาด'
              }
          } else {
              this.status = 'ไม่สามารถเชื่อมต่อกับเครื่องปรินท์ได้'
          }
      } catch (error: any) {
          this.status = 'เกิดข้อผิดพลาดกรุณารีเฟรชหรือกดพิมพ์ใบเสร็จอีกครั้ง'
      }
      this.printLoading = false
    }

    async fetchBills (): Promise<void> {
      try {
        this.loading = true
          this.bill = null
          const upperCaseKeyword = this.billId.toUpperCase()
          const { data } = await orderProvider.fetchBillingByOrderId(upperCaseKeyword)
          if (data) {
              this.bill = data
          } else {
            this.showErrorAlert()
          }
        } catch (error) {
            this.showErrorAlert()
        } finally {
            this.loading = false
        }
    }

    showErrorAlert (): void {
      this.$buefy.dialog.alert({
          title: 'ไม่พบใบเสร็จที่ค้นหา',
          message: `ไม่พบใบเสร็จเลขที่ <b>"${this.billId}"</b> กรุณาตรวจสอบเลขที่ใบเสร็จอีกครั้ง`,
          confirmText: 'ปิด',
          type: 'is-danger',
          hasIcon: true
      })
    }
}
